import { action, Action } from 'easy-peasy';
import { LoadingPayload } from '../utils/interfaces';

export interface PopupsModel {
  isLoadingModalOpen: LoadingPayload;
  showData: boolean;
  setIsLoadingModalOpen: Action<PopupsModel, LoadingPayload>;
  setShowData: Action<PopupsModel, boolean>;
}

const popupsModel: PopupsModel = {
  isLoadingModalOpen: {
    isOpen: true,
    text: 'Loading...',
  },
  showData: false,
  setIsLoadingModalOpen: action((state, payload) => {
    state.isLoadingModalOpen = payload;
  }),
  setShowData: action((state, payload) => {
    state.showData = payload;
  }),
};

export default popupsModel;
