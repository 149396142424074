import React from 'react';
import { AlertTriangle } from 'react-feather';
import styled from 'styled-components';
import Button from '../../components/Button';
import ChartsSection from '../../components/ChartsSection';
import GridContainer from '../../components/GridContainer';
import OutlineHeader from '../../components/OutlineHeader';
import Statistics from '../../components/Statistics';
import { useStoreActions, useStoreState } from '../../store';

const StatisticsContainer = () => {
  const { statisticsModel: statisticsState, settingsModel: settingsState } =
    useStoreState((state) => state);
  const { settingsModel: settingsActions } = useStoreActions((state) => state);
  const { compactMode } = settingsState;
  const handleToggleCompactView = () =>
    settingsActions.setCompactMode(!compactMode);
  return (
    <>
      {statisticsState.loadingError ? (
        <Container>
          <Box>
            <AlertTriangle size="25" />
            <br />
            {statisticsState.loadingError}
          </Box>
        </Container>
      ) : (
        <GridContainer>
          <OutlineHeader
            outline="Protocol"
            text="Analytics"
            color={'blueish'}
          />
          <CompactModeBtn>
            <Button onClick={handleToggleCompactView}>
              {compactMode ? 'Design' : 'Compact'} Mode
            </Button>
          </CompactModeBtn>
          {compactMode ? null : <ChartsSection />}
          <Statistics />
        </GridContainer>
      )}
    </>
  );
};

export default StatisticsContainer;
const CompactModeBtn = styled.div`
  text-align: center;
  margin-bottom: 50px;
  button {
    width: auto;
    padding: 5px;
    border: 0;
    background: ${(props) => props.theme.colors.blueish};
    color: ${(props) => props.theme.colors.neutral};
    font-size: 12px;
    border-radius: 25px;
  }
`;
const Container = styled.div`
  min-height: 42vh;
`;

const Box = styled.div`
  padding: 30px;
  text-align: center;
  max-width: 300px;
  margin: 80px auto;
  background: ${(props) => props.theme.colors.foreground};
  border-radius: ${(props) => props.theme.global.borderRadius};
  color: ${(props) => props.theme.colors.neutral};
  line-height: 1.8;
  svg {
    color: ${(props) => props.theme.colors.yellowish};
  }
`;
