import React, { useState } from 'react';
import { Check, Copy } from 'react-feather';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props {
  value: string;
}
const CopyButton = ({ value }: Props) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyBtn className={copied ? 'copied' : ''}>
      {copied ? (
        <div>
          <Check size="15" /> <span>Copied</span>
        </div>
      ) : (
        <CopyToClipboard
          text={value}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 500);
          }}
        >
          <CopyBtn>
            <Copy size="15" />
          </CopyBtn>
        </CopyToClipboard>
      )}
    </CopyBtn>
  );
};

export default CopyButton;

const CopyBtn = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.customSecondary};
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-size: 11px;
    display: inline-block;
    margin-left: 5px;
  }
  &.copied {
    color: ${(props) => props.theme.colors.greenish};
  }
`;
