import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ResponsiveContainer } from 'recharts';
import { useMedia } from 'react-use';

// Components
import Dropdown from '../../components/Dropdown';
import Chart, { CHART_TYPES } from '../../components/Chart';

// Utils
import { generateData, generatePricesChart } from '../../utils/chart';

// Redux
import { useStoreActions, useStoreState } from '../../store';
import { COIN_TICKER } from '../../utils/constants';
import { CHART_TYPE, IChartData } from '../../utils/interfaces';

interface Props {
  display: CHART_TYPE;
}

const CHART_VIEW = {
  RAI_ISSUED: `${COIN_TICKER} Issued`,
  REDEMPTION_RATE: 'Annualized Redemption Rate',
  PRICES: 'Prices',
};

const VOLUME_WINDOW = {
  QUARTERLY: 'QUARTERLY',
  WEEKLY: 'WEEKLY',
  DAYS: 'DAYS',
  MONTHLY: 'MONTHLY',
};

const Charts = ({ display }: Props) => {
  const { statisticsModel: statisticsActions } = useStoreActions(
    (state) => state
  );
  const { statisticsModel: statisticsState } = useStoreState((state) => state);
  const { stats, pricesChart } = statisticsState;

  const chartData = generateData(stats?.dailyStats, stats?.hourlyStats);
  const pricesChartData = generatePricesChart(pricesChart);

  // chart options
  const [chartView, setChartView] = useState(CHART_VIEW[display]);

  const [volumeWindow, setVolumeWindow] = useState(VOLUME_WINDOW.DAYS);

  const isClient = typeof window === 'object';

  // update the width on a window resize
  const ref = useRef<any>();
  const [width, setWidth] = useState(ref?.current?.container?.clientWidth);

  const belowMedium = useMedia('(max-width: 992px)');

  const handleSelected = (selected: string) => {
    setChartView(selected);
    if (belowMedium) {
      statisticsActions.setSelectedChartName(selected);
    }
  };

  const returnData = (chartData: IChartData, volWindow: string) => {
    switch (volWindow) {
      case 'QUARTERLY':
        return {
          data: chartData.quarterlyRedRates,
          base: chartData.latestQuarterlyRate,
        };
      case 'MONTHLY':
        return {
          data: chartData.monthlyRedRates,
          base: chartData.latestMonthlyRate,
        };
      case 'WEEKLY':
        return {
          data: chartData.weeklyRedRates,
          base: chartData.latestWeeklyRate,
        };

      default:
        return {
          data: chartData.dailyRedRates,
          base: chartData.latestDailyRate,
        };
    }
  };

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => {
      setWidth(ref?.current?.container?.clientWidth ?? width);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, width]); // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      {chartData ? (
        <>
          {belowMedium && (
            <DropDownContainer>
              <Dropdown
                items={Object.values(CHART_VIEW).map((value: string) => value)}
                itemSelected={CHART_VIEW.RAI_ISSUED}
                getSelectedItem={handleSelected}
              />
            </DropDownContainer>
          )}

          {chartData.dailyData.length > 0 &&
          chartView === CHART_VIEW.RAI_ISSUED ? (
            <ResponsiveContainer aspect={60 / 28} ref={ref}>
              <Chart
                data={chartData.dailyData}
                base={chartData.latestAmount}
                title={CHART_VIEW.RAI_ISSUED}
                field="amount"
                width={width}
                type={CHART_TYPES.AREA}
              />
            </ResponsiveContainer>
          ) : null}

          {chartData.dailyRedRates.length > 0 &&
          chartView === CHART_VIEW.REDEMPTION_RATE ? (
            <ChartDataContainer>
              <ResponsiveContainer aspect={60 / 28}>
                <Chart
                  data={returnData(chartData, volumeWindow).data}
                  base={returnData(chartData, volumeWindow).base}
                  title={CHART_VIEW.REDEMPTION_RATE}
                  field={'volume'}
                  width={width}
                  type={CHART_TYPES.BAR}
                  volumeWindow={volumeWindow}
                />
              </ResponsiveContainer>
              <BtnRow
                style={{
                  bottom: '70px',
                  position: 'absolute',
                  left: '0',
                  zIndex: 10,
                }}
              >
                <OptionButton
                  style={{ marginLeft: '15px' }}
                  active={volumeWindow === VOLUME_WINDOW.DAYS}
                  onClick={() => setVolumeWindow(VOLUME_WINDOW.DAYS)}
                >
                  D
                </OptionButton>

                <OptionButton
                  style={{ marginLeft: '4px' }}
                  active={volumeWindow === VOLUME_WINDOW.WEEKLY}
                  onClick={() => setVolumeWindow(VOLUME_WINDOW.WEEKLY)}
                >
                  W
                </OptionButton>

                <OptionButton
                  style={{ marginLeft: '4px' }}
                  active={volumeWindow === VOLUME_WINDOW.MONTHLY}
                  onClick={() => setVolumeWindow(VOLUME_WINDOW.MONTHLY)}
                >
                  M
                </OptionButton>
                <OptionButton
                  style={{ marginLeft: '4px' }}
                  active={volumeWindow === VOLUME_WINDOW.QUARTERLY}
                  onClick={() => setVolumeWindow(VOLUME_WINDOW.QUARTERLY)}
                >
                  Q
                </OptionButton>
              </BtnRow>
            </ChartDataContainer>
          ) : null}
        </>
      ) : null}
      {pricesChartData && chartView === CHART_VIEW.PRICES && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <Chart
            data={pricesChartData.hourlyMarketPrice}
            secondaryData={pricesChartData.hourlyRedemptionPrice}
            base={
              pricesChartData.hourlyMarketPrice[
                pricesChartData.hourlyMarketPrice.length - 1
              ].price
            }
            secondaryBase={
              pricesChartData.hourlyRedemptionPrice[
                pricesChartData.hourlyMarketPrice.length - 1
              ].price
            }
            title={CHART_VIEW.PRICES}
            field="price"
            width={width}
            type={CHART_TYPES.DOUBLE_AREA}
          />
        </ResponsiveContainer>
      )}
    </>
  );
};

export default Charts;

const DropDownContainer = styled.div`
  border-bottom: 10px solid #0b1226;
  button {
    padding: 10px !important;
    font-size: ${(props) => props.theme.font.small} !important;
    width: 100%;
  }

  > div {
    width: 100% !important;
    font-size: ${(props) => props.theme.font.small} !important;

    div {
      width: 100%;
      padding: 10px !important;
    }
  }
`;

const ChartDataContainer = styled.div`
  position: relative;
`;

const BtnRow = styled.div`
  display: flex;
  align-items: center;
`;

const OptionButton = styled.button<{ active?: boolean }>`
  background: ${({ theme, active }) =>
    active ? theme.colors.gradient : theme.colors.neutral};
  box-shadow: none;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.inputBorderColor : theme.colors.border};
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.colors.neutral : theme.colors.primary};
  font-weight: bold;
  border-radius: ${(props) => props.theme.global.borderRadius};
  padding: 5px 8px;
`;
