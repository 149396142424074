import { useMemo } from 'react';
import { Geb } from 'geb.js';
import { ETH_NETWORK, provider } from '../utils/constants';

export default function useGeb(): Geb {
  return useMemo(() => {
    const geb = new Geb(ETH_NETWORK, provider);
    return geb;
  }, []);
}
