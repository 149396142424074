import React from 'react';
import styled from 'styled-components';
import { useStoreState } from '../store';

interface Props {
  height?: number;
}

const Brand = ({ height }: Props) => {
  const { settingsModel: settingsState } = useStoreState((state) => state);
  const imgUrl =
    process.env.PUBLIC_URL +
    '/img/' +
    (settingsState.isLightTheme ? 'brand.svg' : 'brand-white.png');

  return (
    <Container>
      <a href={'/'}>
        <img src={imgUrl} alt="Reflexer" />
      </a>
    </Container>
  );
};

export default Brand;

const Container = styled.div`
  a {
    color: inherit;
    text-decoration: none;
    img {
      width: 160px;
      &.small {
        width: 105.14px;
        height: 25.49px;
      }
      ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 105.14px;
        height: 25.49px;
      }
      `}
    }
  }
`;
