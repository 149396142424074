import { action, Action, thunk, Thunk } from 'easy-peasy';
import { IContractList, IPriceChart, IStats } from '../utils/interfaces';
import { fetchPriceChart, fetchStatistics } from '../services/graphql';
import api from '../services/api';
import { uniqueBy } from '../utils/helper';

export interface StatisticsModel {
  stats: IStats | null;
  pricesChart: Array<IPriceChart>;
  contractList: IContractList | null;
  selectedChartName: string;
  loadingError: string;
  ethFiatPrice: number;
  priceChartIndex: number;
  fetchStatisticsData: Thunk<StatisticsModel, string>;
  fetchPricesChart: Thunk<StatisticsModel>;
  fetchContractList: Thunk<StatisticsModel>;
  fetchEthFiatPrice: Thunk<StatisticsModel>;
  setStats: Action<StatisticsModel, IStats>;
  setPricesChart: Action<StatisticsModel, Array<IPriceChart>>;
  setSelectedChartName: Action<StatisticsModel, string>;
  setLoadingError: Action<StatisticsModel, string>;
  setEthFiatPrice: Action<StatisticsModel, number>;
  setContractList: Action<StatisticsModel, IContractList>;
  setPriceChartIndex: Action<StatisticsModel, number>;
}

const statisticsModel: StatisticsModel = {
  stats: null,
  pricesChart: [],
  priceChartIndex: 0,
  loadingError: '',
  selectedChartName: '',
  contractList: null,
  ethFiatPrice: 0,
  fetchStatisticsData: thunk(async (actions, payload) => {
    const stats = await fetchStatistics(payload);
    actions.setStats(stats);
    actions.setPricesChart(stats.pricesChart);
  }),
  fetchEthFiatPrice: thunk(async (actions) => {
    const fiatPrice = await api.fetchFiatPrice();
    actions.setEthFiatPrice(fiatPrice);
  }),
  fetchContractList: thunk(async (actions) => {
    const contractList = await api.fetchContractList();
    actions.setContractList(contractList);
    return contractList;
  }),
  fetchPricesChart: thunk(async (actions, _payload, { getState }) => {
    const { priceChartIndex } = getState();
    const prices = await fetchPriceChart(priceChartIndex * 500);
    if (prices && prices.hourlyStats.length) {
      actions.setPricesChart(prices.hourlyStats);
      actions.setPriceChartIndex(priceChartIndex + 1);
      return prices.hourlyStats;
    }
    return [];
  }),
  setStats: action((state, payload) => {
    state.stats = payload;
  }),
  setPricesChart: action((state, payload) => {
    let data = [...state.pricesChart, ...payload];
    state.pricesChart = uniqueBy(data, ['timestamp']);
  }),
  setSelectedChartName: action((state, payload) => {
    state.selectedChartName = payload;
  }),
  setLoadingError: action((state, payload) => {
    state.loadingError = payload;
  }),
  setEthFiatPrice: action((state, payload) => {
    state.ethFiatPrice = payload;
  }),
  setContractList: action((state, payload) => {
    state.contractList = payload;
  }),
  setPriceChartIndex: action((state, payload) => {
    state.priceChartIndex = payload;
  }),
};

export default statisticsModel;
