import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Numeral from 'numeral';

// Components
import GlobalChart from '../containers/Charts';

// Redux
import { useStoreState } from '../store';
import { COIN_TICKER } from '../utils/constants';

// Utils
import {
  formatEthAddress,
  formatNumber,
  formattedNum,
  getEtherscanLink,
  getRatePercentage
} from '../utils/helper';
import _ from '../utils/lodash';
import { Info } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CopyButton from './CopyButton';
import usePIRates from '../hooks/usePIRates';

dayjs.extend(relativeTime);

const FLX_ADDRESS = '0x6243d8cea23066d098a15582d81a598b4e8391f4';

const Statistics = () => {
  const { t } = useTranslation();
  const { statisticsModel: statisticsState, settingsModel: settingState } =
    useStoreState(state => state);
  const { compactMode } = settingState;
  const { stats, ethFiatPrice } = statisticsState;
  const { pRate, iRate } = usePIRates();

  const totalDebtAuctions = _.get(
    stats,
    'accountingEngine.debtAuctionCount',
    '0'
  );

  const activeDebtAuctions = _.get(
    stats,
    'accountingEngine.activeDebtAuctions',
    '0'
  );
  const activeSurplusActions = _.get(
    stats,
    'accountingEngine.activeSurplusAuctions',
    '0'
  );

  const totalSurplusActions = _.get(
    stats,
    'accountingEngine.surplusAuctionCount',
    '0'
  );

  const activeLiquidations = _.get(
    stats,
    'collateralType.activeLiquidations',
    '0'
  );
  const annualizedBorrowRate = formattedNum(
    getRatePercentage(
      _.get(stats, 'collateralType.totalAnnualizedStabilityFee', '1')
    )
  );
  const debtAvailableToSettle = _.get(
    stats,
    'systemState.debtAvailableToSettle',
    0
  );
  const debtToSettle = formatNumber(debtAvailableToSettle.toString(), 2);
  const debtCeiling = formatNumber(
    _.get(stats, 'collateralType.debtCeiling', '0'),
    2
  );
  const twapPrice = formatNumber(
    _.get(stats, 'systemState.currentCoinMedianizerUpdate.value', '0')
  );

  const eightHourlyRedemptionRate = formattedNum(
    getRatePercentage(
      _.get(stats, 'systemState.currentRedemptionRate.eightHourlyRate', '1')
    )
  );

  const annualizedRedemptionRate = formattedNum(
    getRatePercentage(
      _.get(stats, 'systemState.currentRedemptionRate.annualizedRate', '1')
    )
  );

  const erc20CoinTotalSupply = formatNumber(
    _.get(stats, 'systemState.erc20CoinTotalSupply', '0'),
    2
  );
  const ethPriceNextOSM = formatNumber(
    _.get(stats, 'collateralType.currentFsmUpdate.nextValue', '0'),
    2
  );
  const lastUpdatedEthPriceNextOSM = _.get(
    stats,
    'collateralType.currentFsmUpdate.createdAt',
    ''
  );

  const ethPriceOsm = formatNumber(
    _.get(stats, 'collateralType.currentFsmUpdate.value', '0'),
    2
  );
  const liquidationsStarted = _.get(
    stats,
    'collateralType.liquidationsStarted',
    '0'
  );
  const outstandingRai = formatNumber(
    _.get(stats, 'systemState.globalDebt', '0'),
    2
  );

  const redemptionPrice = formatNumber(
    _.get(stats, 'systemState.currentRedemptionPrice.value', '0')
  );

  const lastUpdatedRedemptionRate = _.get(
    stats,
    'systemState.currentRedemptionRate.createdAt',
    ''
  );

  const lastUpdatedTWAPPrice = _.get(
    stats,
    'systemState.currentCoinMedianizerUpdate.createdAt',
    ''
  );
  // console.log('test gh actions')
  const marketRedDelta = Math.abs(Number(twapPrice) - Number(redemptionPrice));

  const safesOpen = Number(
    _.get(stats, 'systemState.totalActiveSafeCount', '0')
  );

  const surplus = _.get(stats, 'systemState.systemSurplus', 0);

  const systemSurplus = formatNumber(surplus.toString(), 2);

  const totalEthLocked = formatNumber(
    _.get(stats, 'collateralType.totalCollateral', '0'),
    2
  );
  const surplustInTreasury = formatNumber(
    _.get(stats, 'internalCoinBalances[0].balance', '0')
  );

  const raiTokenAddress = _.get(stats, 'systemState.coinAddress', '');
  const wethTokenAddress = _.get(stats, 'systemState.wethAddress', '');

  let raiUniswapSupply = 0;
  let ticker = `${COIN_TICKER}/ETH`;

  if (raiTokenAddress && wethTokenAddress) {
    if (new BigNumber(raiTokenAddress).lt(new BigNumber(wethTokenAddress))) {
      raiUniswapSupply = formatNumber(
        _.get(stats, 'systemState.coinUniswapPair.reserve0', '0'),
        2
      );
      ticker = `${COIN_TICKER}/ETH`;
    } else {
      raiUniswapSupply = formatNumber(
        _.get(stats, 'systemState.coinUniswapPair.reserve1', '0'),
        2
      );
      ticker = `ETH/${COIN_TICKER}`;
    }
  }

  const returnCommaFormat = (val: number) => {
    //
    // const digitsFormat = val.toString().includes('.') ? '0,0.00' : '0,0';
    return Numeral(val).format('0,0', Math.floor);
  };

  const returnTime = (time: string) => {
    if (time) {
      const now = dayjs(Date.now());

      const from = dayjs(Number(time) * 1000);

      const totalMin = now.diff(from, 'minutes');

      const totalHours = now.diff(from, 'hours');

      const minDiff = totalMin % 60;

      return `${totalHours} hours and ${minDiff} minutes ago`;
    }

    return 'N/A';
  };

  const returnTimeHTMLTip = useCallback(
    (text: string, time: string, updatePeriod = 'every ~24h') => {
      const timeFromNow = returnTime(time);
      return `<div>
    ${t(text)}   
      <p>
       <b>Last Updated:
        ${timeFromNow} (updates ${updatePeriod})</b>
         
      </p>
    </div>`;
    },
    [t]
  );

  const TVL = useMemo(() => {
    if (totalEthLocked) {
      const ethPrice =
        ethFiatPrice && ethFiatPrice > 0 ? ethFiatPrice : Number(ethPriceOsm);
      const number = Number(totalEthLocked) * ethPrice;
      return `($${Numeral(number).format('0 a')})`;
    }
    return '';
  }, [ethFiatPrice, ethPriceOsm, totalEthLocked]);

  const compactStats = useMemo(() => {
    return [
      {
        name: 'Total ETH Locked',
        value: `${returnCommaFormat(totalEthLocked)} ${TVL}`,
        tip: t('total_eth_locked_tip')
      },
      {
        name: 'Outstanding RAI',
        value: `${returnCommaFormat(outstandingRai)} / ${returnCommaFormat(
          debtCeiling
        )}`,
        tip: t('outstanding_rai_tip')
      },
      {
        name: '8-Hourly Redemption Rate',
        value: `${eightHourlyRedemptionRate}%`,
        tip: returnTimeHTMLTip(
          'eight_hourly_red_rate_tip',
          lastUpdatedRedemptionRate
        ),
        isHtml: true
      },
      {
        name: 'Annual Redemption Rate',
        value: `${annualizedRedemptionRate}% ( pRate: ${
          pRate ? formatNumber(pRate.toString(), 3) : '-'
        }% , iRate: ${iRate ? formatNumber(iRate.toString(), 3) : '-'}% )`,
        tip: returnTimeHTMLTip(
          'annual_red_rate_tip',
          lastUpdatedRedemptionRate
        ),
        isHtml: true
      },
      {
        name: 'ERC20 RAI Supply',
        value: returnCommaFormat(erc20CoinTotalSupply),
        tip: t('rai_supply_tip')
      },
      {
        name: `RAI in Uniswap V2 ${ticker}`,
        value: returnCommaFormat(raiUniswapSupply),
        tip: t('rai_in_uniswap_tip')
      },
      {
        name: `Annual Borrow Rate`,
        value: `${annualizedBorrowRate}%`,
        tip: t('annual_borrow_rate_tip')
      },
      {
        name: `Market/Redemption Delta (TWAP)`,
        value: `${
          Number(marketRedDelta.toFixed(4)) > 0 ? marketRedDelta.toFixed(4) : 0
        }USD`,
        tip: t('market_red_delta_tip')
      },
      {
        name: `Surplus Auctions`,
        value: `${activeSurplusActions}`,
        tip: t('active_surplus_auctions_tip')
      },
      {
        name: `Surplus in Treasury`,
        value: `${returnCommaFormat(surplustInTreasury)} ${COIN_TICKER}`,
        tip: t('surplus_in_treasury_tip')
      },
      {
        name: `System Surplus`,
        value: `${returnCommaFormat(systemSurplus)} ${COIN_TICKER}`,
        tip: t('system_surplus_tip')
      },
      {
        name: `Active Safes`,
        value: `${returnCommaFormat(safesOpen)}`,
        tip: t('active_safes_tip')
      },
      {
        name: `ETH Collateral Auctions`,
        value: `${liquidationsStarted}`,
        tip: t('eth_collateral_auctions_tip')
      },
      {
        name: `Active ETH Collateral Auctions`,
        value: `${activeLiquidations}`,
        tip: t('active_eth_collateral_auctions_tip')
      },
      {
        name: `RAI Market Price (TWAP)`,
        value: `${twapPrice} USD`,
        tip: returnTimeHTMLTip(
          'rai_price_twap_tip',
          lastUpdatedTWAPPrice,
          'every ~12h'
        ),
        isHtml: true
      },
      {
        name: `RAI Redemption Price`,
        value: `${redemptionPrice} USD`,
        tip: t('rai_red_price_tip')
      },
      {
        name: `ETH Price (OSM)`,
        value: `${returnCommaFormat(ethPriceOsm)} USD`,
        tip: t('eth_osm_tip')
      },
      {
        name: `ETH Price (Next OSM)`,
        value: `${returnCommaFormat(ethPriceNextOSM)} USD`,
        tip: returnTimeHTMLTip(
          'eth_next_osm_tip',
          lastUpdatedEthPriceNextOSM,
          'at least every 8h'
        ),
        isHtml: true
      },
      {
        name: `Debt Auctions`,
        value: `${activeDebtAuctions}`,
        tip: t('active_debt_auctions_tip')
      },
      {
        name: `RAI Addresss`,
        value: (
          <LinkBtn
            href={getEtherscanLink(raiTokenAddress, 'token')}
            target={'_blank'}
          >
            {formatEthAddress(raiTokenAddress, 2)}
          </LinkBtn>
        )
      },
      {
        name: `ETH Addresss`,
        value: (
          <LinkBtn
            href={getEtherscanLink(wethTokenAddress, 'token')}
            target={'_blank'}
          >
            {formatEthAddress(wethTokenAddress, 2)}
          </LinkBtn>
        )
      },
      {
        name: `FLX Addresss`,
        value: (
          <LinkBtn
            href={getEtherscanLink(FLX_ADDRESS, 'token')}
            target={'_blank'}
          >
            {formatEthAddress(FLX_ADDRESS, 2)}
          </LinkBtn>
        )
      }
    ];
  }, [
    TVL,
    activeDebtAuctions,
    activeLiquidations,
    activeSurplusActions,
    annualizedBorrowRate,
    annualizedRedemptionRate,
    debtCeiling,
    eightHourlyRedemptionRate,
    erc20CoinTotalSupply,
    ethPriceNextOSM,
    ethPriceOsm,
    iRate,
    lastUpdatedEthPriceNextOSM,
    lastUpdatedRedemptionRate,
    lastUpdatedTWAPPrice,
    liquidationsStarted,
    marketRedDelta,
    outstandingRai,
    pRate,
    raiTokenAddress,
    raiUniswapSupply,
    redemptionPrice,
    returnTimeHTMLTip,
    safesOpen,
    surplustInTreasury,
    systemSurplus,
    t,
    ticker,
    totalEthLocked,
    twapPrice,
    wethTokenAddress
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [compactMode]);

  return (
    <Container>
      {compactMode ? (
        <Table>
          {compactStats.map(stat => {
            return (
              <TableRow key={stat.name}>
                <TabelCell>
                  {stat.name}{' '}
                  {stat.tip ? (
                    <InfoIcon
                      onMouseEnter={() => ReactTooltip.rebuild()}
                      data-tip={stat.tip}
                      data-html={stat.isHtml ? true : false}
                    >
                      <Info size='14' />
                    </InfoIcon>
                  ) : null}
                </TabelCell>
                <TabelCell>{stat.value}</TabelCell>
              </TableRow>
            );
          })}
        </Table>
      ) : (
        <div>
          <Row className='col3'>
            <Col className='col3'>
              <ColInner>
                <InfoIcon data-tip={t('total_eth_locked_tip')}>
                  <Info size='16' />
                </InfoIcon>
                <img
                  src={process.env.PUBLIC_URL + '/img/stat1.svg'}
                  alt='stat1'
                />
                <p>Total ETH Locked</p>
                <h3>
                  {returnCommaFormat(totalEthLocked)} {TVL}
                </h3>
              </ColInner>
            </Col>

            <Col className='col3'>
              <ColInner>
                <InfoIcon data-tip={t('outstanding_rai_tip')}>
                  <Info size='16' />
                </InfoIcon>
                <img
                  src={process.env.PUBLIC_URL + '/img/stat2.svg'}
                  alt='stat1'
                />
                <p>Outstanding RAI</p>
                <h3>{`${returnCommaFormat(
                  outstandingRai
                )} / ${returnCommaFormat(debtCeiling)}`}</h3>
              </ColInner>
            </Col>

            <Col className='col3'>
              <ColInner>
                <InfoIcon data-tip={t('active_safes_tip')}>
                  <Info size='16' />
                </InfoIcon>
                <img
                  src={process.env.PUBLIC_URL + '/img/stat3.svg'}
                  alt='stat1'
                />
                <p>Active Safes</p>
                <h3>{returnCommaFormat(safesOpen)}</h3>
              </ColInner>
            </Col>
          </Row>

          <CurrencyBlock>
            <ColoredTitle color={'blueish'}>
              Major currencies and RAI
            </ColoredTitle>
            <Row className='col5Block'>
              <iframe
                title='Major currencies and RAI'
                src='https://dune.com/embeds/3951015/6646923/'
                style={{ width: '100%', height: '340px' }}
              />
            </Row>
            <div style={{ marginTop: 20 }}>
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                target='_blank'
                rel='noopener noreferrer'
                href='https://dune.com/kyoronut/RAI'
              >
                More Dune Dashboards
              </a>
            </div>
          </CurrencyBlock>

          <Row className='col50'>
            <Col className='col50 fullHeight fixMobile'>
              <ColInner className='inner'>
                <ColoredTitle color={'blueish'}>System Rates</ColoredTitle>
                <Row className='col3 fullHeight'>
                  <Col className='col3 fullHeight'>
                    <ColInner>
                      <InfoIcon data-tip={t('annual_borrow_rate_tip')}>
                        <Info size='16' />
                      </InfoIcon>
                      <p>Annual Borrow Rate</p>
                      <h4>{annualizedBorrowRate}%</h4>
                    </ColInner>
                  </Col>

                  <Col className='col3 fullHeight'>
                    <ColInner className='greenish'>
                      <InfoIcon
                        data-tip={returnTimeHTMLTip(
                          'annual_red_rate_tip',
                          lastUpdatedRedemptionRate
                        )}
                        data-html
                      >
                        <Info size='16' />
                      </InfoIcon>
                      <p>Annual Redemption Rate</p>
                      <h4>{annualizedRedemptionRate}%</h4>

                      <Flex>
                        <Column>
                          <span>pRate: </span>
                          {pRate ? formatNumber(pRate.toString(), 3) : '-'}%
                        </Column>
                        <Column>
                          <span>iRate: </span>
                          {iRate ? formatNumber(iRate.toString(), 3) : '-'}%
                        </Column>
                      </Flex>
                    </ColInner>
                  </Col>

                  <Col className='col3 fullHeight'>
                    <ColInner>
                      <InfoIcon
                        data-tip={returnTimeHTMLTip(
                          'eight_hourly_red_rate_tip',
                          lastUpdatedRedemptionRate
                        )}
                        data-html
                      >
                        <Info size='16' />
                      </InfoIcon>
                      <p>8-Hourly Redemption Rate</p>
                      <h4>{eightHourlyRedemptionRate}%</h4>
                    </ColInner>
                  </Col>
                </Row>
              </ColInner>
            </Col>

            <Col className='col50 fullHeight fixMobile'>
              <ColInner className='inner'>
                <ColoredTitle color={'greenish'}>System Info</ColoredTitle>
                <Row>
                  <Col className='col50 normalBlocks'>
                    <ColInner>
                      <InfoIcon data-tip={t('rai_supply_tip')}>
                        <Info size='16' />
                      </InfoIcon>
                      <p>ERC20 RAI Supply</p>
                      <h4>{returnCommaFormat(erc20CoinTotalSupply)}</h4>
                    </ColInner>
                  </Col>
                  <Col className='col50 normalBlocks'>
                    <ColInner>
                      <InfoIcon data-tip={t('rai_in_uniswap_tip')}>
                        <Info size='16' />
                      </InfoIcon>
                      <p>RAI in Uniswap V2 ({ticker})</p>
                      <h4>{returnCommaFormat(raiUniswapSupply)}</h4>
                    </ColInner>
                  </Col>
                </Row>

                <Row className='col3'>
                  <Col className='col3 normalBlocks fixMargin'>
                    <ColInner>
                      <InfoIcon data-tip={t('system_surplus_tip')}>
                        <Info size='16' />
                      </InfoIcon>
                      <p>System Surplus</p>
                      <h4>
                        {returnCommaFormat(systemSurplus)} {COIN_TICKER}
                      </h4>
                    </ColInner>
                  </Col>

                  <Col className='col3 normalBlocks fixMargin'>
                    <ColInner>
                      <InfoIcon data-tip={t('surplus_in_treasury_tip')}>
                        <Info size='16' />
                      </InfoIcon>
                      <p>Surplus in Treasury</p>
                      <h4>
                        {returnCommaFormat(surplustInTreasury)} {COIN_TICKER}
                      </h4>
                    </ColInner>
                  </Col>

                  <Col className='col3 normalBlocks fixMargin'>
                    <ColInner>
                      <InfoIcon data-tip={t('debt_to_settle_tip')}>
                        <Info size='16' />
                      </InfoIcon>
                      <p>Debt to Settle</p>
                      <h4>
                        {returnCommaFormat(debtToSettle)} {COIN_TICKER}
                      </h4>
                    </ColInner>
                  </Col>
                </Row>
              </ColInner>
            </Col>
          </Row>

          <PricesBlock>
            <ColoredTitle color={'yellowish'}>Prices</ColoredTitle>
            <Row className='col5Block'>
              <Col className='col5Block'>
                <ColInner>
                  <InfoIcon data-tip={t('market_red_delta_tip')}>
                    <Info size='16' />
                  </InfoIcon>
                  <p>Market/Redemption Delta (TWAP)</p>
                  <h4>
                    {Number(marketRedDelta.toFixed(4)) > 0
                      ? marketRedDelta.toFixed(4)
                      : 0}{' '}
                    USD
                  </h4>
                </ColInner>
              </Col>

              <Col className='col5Block'>
                <ColInner>
                  <InfoIcon
                    data-tip={returnTimeHTMLTip(
                      'rai_price_twap_tip',
                      lastUpdatedTWAPPrice,
                      'every ~12h'
                    )}
                    data-html
                  >
                    <Info size='16' />
                  </InfoIcon>
                  <p>RAI Market Price (TWAP)</p>
                  <h4>{`${twapPrice} USD`}</h4>
                </ColInner>
              </Col>

              <Col className='col5Block'>
                <ColInner>
                  <InfoIcon data-tip={t('rai_red_price_tip')}>
                    <Info size='16' />
                  </InfoIcon>
                  <p>RAI Redemption Price</p>
                  <h4>{`${redemptionPrice} USD`}</h4>
                </ColInner>
              </Col>

              <Col className='col5Block'>
                <ColInner>
                  <InfoIcon data-tip={t('eth_osm_tip')}>
                    <Info size='16' />
                  </InfoIcon>
                  <p>ETH Price (OSM)</p>
                  <h4>{`${returnCommaFormat(ethPriceOsm)} USD`}</h4>
                </ColInner>
              </Col>

              <Col className='col5Block'>
                <ColInner>
                  <InfoIcon
                    data-tip={returnTimeHTMLTip(
                      'eth_next_osm_tip',
                      lastUpdatedEthPriceNextOSM,
                      'at least every 8h'
                    )}
                    data-html
                  >
                    <InfoIcon data-tip={t('eth_next_osm_tip')}></InfoIcon>
                    <Info size='16' />
                  </InfoIcon>
                  <p>ETH Price (Next OSM)</p>
                  <h4>{`${returnCommaFormat(ethPriceNextOSM)} USD`}</h4>
                </ColInner>
              </Col>
            </Row>
          </PricesBlock>

          <Row>
            <Col className='col55'>
              <ColInner className='inner'>
                <ColoredTitle color={'greenish'}>Auctions</ColoredTitle>
                <ColInner className='table table-head'>
                  <Row>
                    <Col className='tableCell big'>Type</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>Active</Col>
                        <Col className='col50'>Total</Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>
                <ColInner className='table'>
                  <InfoIcon data-tip={t('eth_collateral_auctions_tip')}>
                    <Info size='16' />
                  </InfoIcon>
                  <Row>
                    <Col className='tableCell big'>ETH Collateral Auctions</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>{activeLiquidations}</Col>
                        <Col className='col50'>{liquidationsStarted}</Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>
                <ColInner className='table mid'>
                  <InfoIcon data-tip={t('active_surplus_auctions_tip')}>
                    <Info size='16' />
                  </InfoIcon>
                  <Row>
                    <Col className='tableCell big'>Surplus Auctions</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>{activeSurplusActions}</Col>
                        <Col className='col50'>{totalSurplusActions}</Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>

                <ColInner className='table'>
                  <InfoIcon data-tip={t('active_debt_auctions_tip')}>
                    <Info size='16' />
                  </InfoIcon>
                  <Row>
                    <Col className='tableCell big'>Debt Auctions</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>{activeDebtAuctions}</Col>
                        <Col className='col50'>{totalDebtAuctions}</Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>
              </ColInner>
            </Col>
            <Col className='col45'>
              <ColInner className='inner'>
                <ColoredTitle color={'blueish'}>Token Addresses</ColoredTitle>

                <ColInner className='table'>
                  <Row>
                    <Col className='tableCell big'>RAI Address</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>
                          <LinkBtn
                            href={getEtherscanLink(raiTokenAddress, 'token')}
                            target={'_blank'}
                          >
                            {formatEthAddress(raiTokenAddress, 2)}
                          </LinkBtn>
                        </Col>
                        <Col className='col50'>
                          <CopyButton value={raiTokenAddress} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>
                <ColInner className='table mid'>
                  <Row>
                    <Col className='tableCell big'>ETH Address</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>
                          <LinkBtn
                            href={getEtherscanLink(wethTokenAddress, 'token')}
                            target={'_blank'}
                          >
                            {formatEthAddress(wethTokenAddress, 2)}
                          </LinkBtn>
                        </Col>
                        <Col className='col50'>
                          <CopyButton value={wethTokenAddress} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>
                <ColInner className='table'>
                  <Row>
                    <Col className='tableCell big'>FLX Address</Col>
                    <Col className='tableCell small'>
                      <Row style={{ flexDirection: 'row' }}>
                        <Col className='col50'>
                          <LinkBtn
                            href={getEtherscanLink(FLX_ADDRESS, 'token')}
                            target={'_blank'}
                          >
                            {formatEthAddress(FLX_ADDRESS, 2)}
                          </LinkBtn>
                        </Col>
                        <Col className='col50'>
                          <CopyButton value={FLX_ADDRESS} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ColInner>
              </ColInner>
            </Col>
          </Row>

          <ChartsContainer>
            <Panel style={{ height: '100%', minHeight: '300px' }}>
              <PanelInner>
                <ColoredTitle color={'blueish'}>
                  {statisticsState.selectedChartName || `${COIN_TICKER} Issued`}
                </ColoredTitle>
                <ChartContent>
                  <GlobalChart display='RAI_ISSUED' />
                </ChartContent>
              </PanelInner>
            </Panel>
            <Panel className='disableMobile' style={{ height: '100%' }}>
              <PanelInner>
                <ColoredTitle color={'greenish'}>
                  {'Redemption Rate'}
                </ColoredTitle>
                <ChartContent>
                  <GlobalChart display='REDEMPTION_RATE' />
                </ChartContent>
              </PanelInner>
            </Panel>

            <Panel className='disableMobile' style={{ height: '100%' }}>
              <PanelInner>
                <ColoredTitle color={'yellowish'}>{'Prices'}</ColoredTitle>
                <ChartContent>
                  <GlobalChart display='PRICES' />
                </ChartContent>
              </PanelInner>
            </Panel>
          </ChartsContainer>
        </div>
      )}
      <ReactTooltip multiline type='light' data-effect='solid' />
    </Container>
  );
};

export default Statistics;

const Container = styled.div`
  padding: 0 15px;
`;
const Row = styled.div`
  display: flex;
  margin: 0 -10px;

  p {
    color: ${props => props.theme.colors.customSecondary};
    font-size: 14px;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    color: ${props => props.theme.colors.neutral};
  }
  &.fullHeight {
    height: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  padding: 0 10px;
  &.col55 {
    flex: 0 0 55%;
    margin: 40px 0 20px 0;

    .inner {
      background: #0b1226;
      padding: 50px 30px 30px 30px;
      @media (max-width: 991px) {
        padding: 50px 10px 20px 10px;
      }
    }
  }
  &.col45 {
    flex: 0 0 45%;
    margin: 40px 0 20px 0;
    .inner {
      background: #0b1226;
      padding: 50px 30px 30px 30px;
      @media (max-width: 991px) {
        padding: 50px 10px 20px 10px;
      }
    }
  }
  &.col50 {
    flex: 0 0 50%;
    &.normalBlocks {
      margin: 20px 0 20px 0;
      text-align: center;
      p {
        width: 100% !important;
      }
      > div {
        padding: 40px 10px;
      }
    }
    &.fullHeight {
      margin: 40px 0 20px 0;

      .greenish {
        background: ${props => props.theme.colors.greenish};
        p {
          color: ${props => props.theme.colors.background};
        }
        h4 {
          color: ${props => props.theme.colors.background};
        }
      }
      .inner {
        padding: 20px 30px 10px;
        background: #0b1226;
        height: 100%;
      }
      p {
        width: 100px;
        line-height: 1.5;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      h4 {
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: ${props => props.theme.colors.neutral};
      }
    }
  }

  &.col50 {
    @media (max-width: 991px) {
      margin: 10px 0 !important;
      .inner {
        padding: 30px 10px 20px 10px !important;
      }
      &.fixMobile {
        margin-top: 60px !important;
      }
    }
  }
  &.col5Block {
    flex: 0 0 20%;
    padding: 10px;
    p {
      max-width: 80%;
      text-align: center;
      line-height: 1.5;
      margin-top: 0;
      min-height: 42px;
    }
    h4 {
      font-family: 'Montserrat', sans-serif;
      color: ${props => props.theme.colors.neutral};
      margin: 0;
      font-size: 1.3rem;
    }
    > div {
      padding: 40px 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 991px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.tableCell {
    a {
      color: inherit;
      transition: all 0.3s ease;
      &:hover {
        color: ${props => props.theme.colors.greenish};
      }
    }
    .col50 {
      padding: 0;
    }
    &.small {
      text-align: center;
      flex: 0 0 30%;
      color: ${props => props.theme.colors.neutral};
      font-family: 'Montserrat', sans-serif;
      @media (max-width: 991px) {
        flex: 0 0 50%;
      }
    }
    &.big {
      text-align: left;
      flex-grow: 1;
      color: ${props => props.theme.colors.customSecondary};
      @media (max-width: 991px) {
        flex: 0 0 50%;
      }
    }
  }

  &.col3 {
    &.normalBlocks {
      &.fixMargin {
        margin: 0px 0 20px 0;
        @media (max-width: 991px) {
          margin: 10px 0;
        }
      }
      text-align: center;
      p {
        width: 100% !important;
        margin-top: 0;
      }
      h4 {
        font-size: 1.1rem;
      }
    }

    margin: 20px 0;
    flex: 0 0 33.33%;
    text-align: center;

    > div {
      padding: 40px 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    p {
      margin-top: 20px;
    }
    img {
      max-width: 50px;
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 0;
      margin-top: 10px;
    }
    &.col3 {
      @media (max-width: 991px) {
        margin: 10px 0;
        .inner {
          padding: 20px 10px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    h4 {
      font-size: 1.3rem !important;
    }
  }
`;

const ColInner = styled.div`
  position: relative;
  padding: 20px;
  background: ${props => props.theme.colors.foreground};
  border-radius: ${props => props.theme.global.borderRadius};
  &.mid {
    margin: 20px 0;
  }
  &.table {
    > div {
      align-items: center;
      flex-direction: row !important;
    }
    &.table-head {
      background: transparent;
      font-style: italic;
      position: absolute;
      width: 100%;
      top: 40px;
      z-index: 3;
      right: 0px;
      padding: 0 50px;

      .tableCell {
        color: #046772;
        font-family: inherit;
      }
      .inner {
        background: transparent;
      }
      @media (max-width: 991px) {
        top: 20px;
        padding: 0 30px;
      }
    }
  }
`;
const PricesBlock = styled.div`
  background: #0b1226;
  padding: 30px;
  position: relative;
  margin: 40px 0 20px;
  @media (max-width: 991px) {
    padding: 30px 10px 10px 10px;
  }
`;

const CurrencyBlock = styled.div`
  background: #0b1226;
  padding: 30px;
  position: relative;
  margin: 40px 0 20px;
  @media (max-width: 991px) {
    padding: 30px 10px 10px 10px;
  }
`;

const ColoredTitle = styled.h2<{
  color?: 'blueish' | 'greenish' | 'yellowish';
}>`
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.blueish};
  font-family: 'unicode_impact';
  font-size: 2.5rem;
  margin: 0;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  top: -20px;
`;

const ChartsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px 30px -10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin:0 0 30px 0;
    
    .disableMobile {
      display:none;
    }
  `}
`;

const Panel = styled.div`
  flex: 0 0 50%;
  padding: 0 10px;
  margin: 40px 0 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex: 0 0 100%;
    padding:0;
    
    &:first-child {
      margin-bottom:15px;
    }
  `}
`;

const PanelInner = styled.div`
  padding: 30px;
  border-radius: ${props => props.theme.global.borderRadius};
  background: #0b1226;
  position: relative;

  @media (max-width: 991px) {
    padding: 0px 10px 10px 10px;
    ${ColoredTitle} {
      position: relative;
      top: -15px;
    }
  }
`;

const ChartContent = styled.div`
  background: ${props => props.theme.colors.background};
`;

const LinkBtn = styled.a``;

const InfoIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  svg {
    fill: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.yellowish};
  }
`;

const Table = styled.div`
  max-width: 600px;
  margin: 0 auto 30px auto;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.background};
  &:nth-child(odd) {
    background: ${props => props.theme.colors.foreground};
  }
`;

const TabelCell = styled.div`
  border: 1px solid rgb(8, 34, 62);
  padding: 10px;
  flex: 0 0 50%;
  position: relative;
  color: ${props => props.theme.colors.customSecondary};
  font-size: 15px;
  a {
    color: ${props => props.theme.colors.blueish};
  }
`;

const Flex = styled.div`
  text-align: left;
  margin-top: 20px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  span {
    font-weight: bold;
    margin-right: 5px;
  }
`;
