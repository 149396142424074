import { createGlobalStyle, css } from 'styled-components';

interface Props {
  bodyOverflow?: boolean;
}

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "unicode_impact";
  src: url("../fonts/Impact/unicode_impact.eot");
  src: url("../fonts/Impact/unicode_impact.eot") format("embedded-opentype"),
    url("../fonts/Impact/unicode_impact.woff2") format("woff2"),
    url("../fonts/Impact/unicode_impact.woff") format("woff"),
    url("../fonts/Impact/unicode_impact.ttf") format("truetype"),
    url("../fonts/Impact/unicode_impact.svg#unicode_impact") format("svg");
}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
        body {
          background-color:${(props) => props.theme.colors.background};
          background-image: url('../img/bg.png');
          background-size:cover;
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-position:center;
            overflow: ${(props: Props) =>
              props.bodyOverflow ? 'hidden' : 'visible'};

.web3modal-modal-lightbox {
  z-index: 999;

  .web3modal-modal-card {
    display:block;
    max-width:400px;
    .web3modal-provider-container {
      display:flex;
      flex-direction:row;
     justify-content:space-between;
     align-items:center;
     padding:16px;
      
     .web3modal-provider-name{
       font-size: 16px;
       width:auto;
     }

     .web3modal-provider-icon{
       order:2;
       width:30px;
       height:30px;

     }
      .web3modal-provider-description {
      display:none;
    }
    }
   
  }
}
        }

        .__react_component_tooltip {
    max-width: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    color:${(props) => props.theme.colors.customSecondary} !important;
    opacity: 1 !important;
    background: ${(props) => props.theme.colors.foreground} !important;
    border: ${(props) => props.theme.colors.border} !important;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    &:after {
      border-top-color: ${(props) => props.theme.colors.foreground} !important;
    }
  }
        


.three-line-legend {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: #20262E;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

.three-line-legend-dark {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: white;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}


.tv-lightweight-charts{
  width: 100% !important;
  

  & > * {
    width: 100% !important;
  }
}
`;

export const ExternalLinkArrow = css`
  border: 0;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.colors.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: ${(props) => props.theme.colors.inputBorderColor};
  font-size: ${(props) => props.theme.font.small};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }

  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  img {
    position: relative;
    top: 1px;
  }
`;

export const BtnStyle = css`
  outline: none;
  cursor: pointer;
  min-width: 134px;
  border: none;
  box-shadow: none;
  padding: ${(props) => props.theme.global.buttonPadding};
  line-height: 24px;
  font-size: ${(props) => props.theme.font.small};
  font-weight: 600;
  font-family: 'Inter-Medium';
  color: ${(props) => props.theme.colors.neutral};
  background: ${(props) => props.theme.colors.gradient};
  border-radius: ${(props) => props.theme.global.borderRadius};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: space-between;
`;

export default GlobalStyle;
