import popupsModel, { PopupsModel } from './popupsModel';
import statisticsModel, { StatisticsModel } from './statisticsModel';
import settingsModel, { SettingsModel } from './settingsModel';

export interface StoreModel {
  popupsModel: PopupsModel;
  statisticsModel: StatisticsModel;
  settingsModel: SettingsModel;
}

const model: StoreModel = {
  settingsModel,
  popupsModel,
  statisticsModel,
};

export default model;
