import {
  IChartData,
  IDailyStats,
  IHourlyStats,
  IPriceChart,
  IPriceChartData,
} from './interfaces';
import { generateRedemptionRates, getRatePercentage } from './helper';

export const generateData = (
  dailyStats?: Array<IDailyStats>,
  hourlyStats?: Array<IHourlyStats>
): IChartData | null => {
  if (
    !dailyStats ||
    dailyStats.length === 0 ||
    !hourlyStats ||
    hourlyStats.length === 0
  ) {
    return null;
  }

  const dailyData = dailyStats.map((dailyStat: IDailyStats) => {
    return {
      amount: dailyStat.globalDebt,
      date: Number(dailyStat.timestamp),
    };
  });

  const dailyRedRates = dailyStats.map((dailyStat: IDailyStats) => {
    return {
      volume: getRatePercentage(
        dailyStat.redemptionRate.annualizedRate
      ).toString(),
      date: Number(dailyStat.timestamp),
    };
  });

  const weeklyRedRates = generateRedemptionRates(dailyStats);
  const monthlyRedRates = generateRedemptionRates(dailyStats, 'monthly');
  const quarterlyRedRates = generateRedemptionRates(dailyStats, 'quarterly');

  return {
    dailyData,
    weeklyRedRates,
    monthlyRedRates,
    dailyRedRates,
    quarterlyRedRates,
    latestQuarterlyRate: quarterlyRedRates[quarterlyRedRates.length - 1].volume,
    latestAmount: dailyData[dailyData.length - 1].amount,
    latestWeeklyRate: weeklyRedRates[weeklyRedRates.length - 1].volume,
    latestMonthlyRate: monthlyRedRates[monthlyRedRates.length - 1].volume,
    latestDailyRate: dailyRedRates[dailyRedRates.length - 1].volume,
  };
};

// price chart data

export const generatePricesChart = (
  hourlyStats: Array<IPriceChart>
): IPriceChartData | null => {
  if (!hourlyStats || hourlyStats.length === 0) {
    return null;
  }
  const hourlyMarketPrice = hourlyStats.map((hourlyStat: IPriceChart) => {
    return {
      date: Number(hourlyStat.timestamp),
      price: hourlyStat.marketPriceUsd,
    };
  });

  const hourlyRedemptionPrice = hourlyStats.map((hourlyStat: IPriceChart) => {
    return {
      date: Number(hourlyStat.timestamp),
      price: hourlyStat.redemptionPrice.value,
    };
  });

  return {
    hourlyMarketPrice: hourlyMarketPrice.reverse(),
    hourlyRedemptionPrice: hourlyRedemptionPrice.reverse(),
  };
};
