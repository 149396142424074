export const statisticsQuery = (GEB_STABILITY_FEE_TREASURY: string) => `{
  internalCoinBalances(where: {accountHandler: "${GEB_STABILITY_FEE_TREASURY}"}) {
    balance
  }
  accountingEngine(id: "current") {
    activeDebtAuctions
    activeSurplusAuctions
    debtAuctionCount
    surplusAuctionCount
  }
  collateralType(id: "ETH-A") {
    currentFsmUpdate{
      value
      nextValue
      createdAt
    }
    debtCeiling
    activeLiquidations
    liquidationsStarted
    totalCollateral
    totalAnnualizedStabilityFee
  }
  systemState(id: "current") {
    coinUniswapPair {
      reserve0
      reserve1
    }
    currentCoinMedianizerUpdate{
      value
      createdAt
    }
    currentRedemptionRate {
      eightHourlyRate
      annualizedRate
      hourlyRate
      createdAt
    }
    currentRedemptionPrice {
      value
    }
    erc20CoinTotalSupply
    globalDebt
    globalDebtCeiling
    totalActiveSafeCount
    coinAddress
    wethAddress
    systemSurplus
    debtAvailableToSettle
  }
 
  dailyStats(first: 1500, orderDirection: desc) {
    globalDebt
    timestamp
    redemptionRate {
      annualizedRate
    }
  }
  hourlyStats(first: 1000, orderBy: timestamp, orderDirection: desc) {
    globalDebt
    timestamp
    redemptionRate {
      hourlyRate
    }
  }

  pricesChart:hourlyStats(first: 500, orderBy: timestamp, orderDirection: desc) {
    timestamp
    redemptionPrice{
      value
    }
    marketPriceUsd
  }
}`;

export const priceQuery = (skip: number) => `{
  hourlyStats(skip: ${skip},first: 500, orderBy: timestamp, orderDirection: desc) {
    timestamp
    redemptionPrice{
      value
    }
    marketPriceUsd
  }
}`;
