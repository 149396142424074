import { ethers } from 'ethers';
import { css } from 'styled-components';

const {
  REACT_APP_GRAPH_API_URLS,
  REACT_APP_NETWORK_ID,
  REACT_APP_CONTRACT_LIST,
  REACT_APP_NETWORK_URL
} = process.env;

export enum Network {
  MAINNET = 'mainnet',
  KOVAN = 'kovan'
}

export const NETWORK_URL = REACT_APP_NETWORK_URL ?? '';

export const provider = new ethers.providers.JsonRpcProvider(NETWORK_URL);

export const ETH_NETWORK =
  REACT_APP_NETWORK_ID === '1' ? Network.MAINNET : Network.KOVAN;

export const COIN_TICKER = 'RAI';

export const GRAPH_API_URLS = REACT_APP_GRAPH_API_URLS
  ? REACT_APP_GRAPH_API_URLS.split(',')
  : [
      // 'https://api.thegraph.com/subgraphs/name/reflexer-labs/rai-mainnet',
      // 'https://subgraph.reflexer.finance/subgraphs/name/reflexer-labs/rai',
      // "https://api.thegraph.com/subgraphs/name/reflexer-labs/rai-mainnet-prod",
      'https://subgraph.satsuma-prod.com/ea4569e42b10/duuvf2ayrz8e0yxg0udnic--151680/rai-mainnet-production/version/v0.0.1/api'
    ];

export const CONTRACT_LIST =
  REACT_APP_CONTRACT_LIST ??
  'https://raw.githubusercontent.com/reflexer-labs/geb-changelog/master/releases/mainnet/2.0.0/median/fixed-discount/contracts.json';

export const MAILCHIMP_URL =
  'https://finance.us20.list-manage.com/subscribe/post-json?u=a3b1b26a531e341b4e086da36&id=b5976e4e32';

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  THREE_MONTHS: '3 months',
  ALL_TIME: 'All time'
};

const MEDIA_WIDTHS = {
  upToExtraSmall: 576,
  upToSmall: 768,
  upToMedium: 992,
  upToLarge: 1280,
  upToExtraLarge: 1366
};

export const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;
