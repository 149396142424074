import axios from 'axios';
import retry from 'async-retry';
import { GRAPH_API_URLS } from '../utils/constants';
import { priceQuery, statisticsQuery } from '../utils/queries/statistics';

export const fetchStatistics = (GEB_STABILITY_FEE_TREASURY: string) => {
  return retry(
    async (bail, attempt) => {
      const res = await axios.post(
        GRAPH_API_URLS[attempt - 1],
        JSON.stringify({ query: statisticsQuery(GEB_STABILITY_FEE_TREASURY) }),
        {
          headers: { 'content-type': 'application/json' },
        }
      );

      // Retry if returned data is empty
      if (
        (!res.data.data || !res.data.data.collateralType) &&
        attempt < GRAPH_API_URLS.length
      ) {
        throw new Error('retry');
      }

      const stats = res.data.data;

      return stats;
    },
    {
      retries: GRAPH_API_URLS.length - 1,
    }
  );
};

export const fetchPriceChart = (skip: number) => {
  return retry(
    async (bail, attempt) => {
      const res = await axios.post(
        GRAPH_API_URLS[attempt - 1],
        JSON.stringify({ query: priceQuery(skip) }),
        {
          headers: { 'content-type': 'application/json' },
        }
      );

      // Retry if returned data is empty
      if (
        (!res.data.data || !res.data.data.collateralType) &&
        attempt < GRAPH_API_URLS.length
      ) {
        throw new Error('retry');
      }

      const stats = res.data.data;

      return stats;
    },
    {
      retries: GRAPH_API_URLS.length - 1,
    }
  );
};
