import React, { useState, useEffect, useRef } from 'react';
import { createChart, IChartApi, ISeriesApi } from 'lightweight-charts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import { formattedNum } from '../utils/helper';
import { Play } from 'react-feather';
import { COIN_TICKER } from '../utils/constants';
import store from '../store';
import { generatePricesChart } from '../utils/chart';
import { usePrevious } from 'react-use';

dayjs.extend(utc);

export const CHART_TYPES = {
  BAR: 'BAR',
  AREA: 'AREA',
  DOUBLE_AREA: 'DOUBLE_AREA',
};

const Wrapper = styled.div`
  position: relative;
`;

// constant height for charts
const HEIGHT = 350;

const Chart = ({
  type = CHART_TYPES.BAR,
  data,
  base,
  secondaryBase,
  field,
  title,
  width,
  secondaryData,
  volumeWindow,
}: any) => {
  // reference for DOM element to create with chart
  const ref = useRef<any>();

  // pointer to the chart object
  const [chartCreated, setChartCreated] = useState<IChartApi | null>();

  const dataPrev = usePrevious(data);

  useEffect(() => {
    if (data !== dataPrev && chartCreated && type === CHART_TYPES.BAR) {
      // remove the tooltip element
      let tooltip = document.getElementById('tooltip-id' + type);
      let node = document.getElementById('test-id' + type);

      if (node && tooltip) {
        node.removeChild(tooltip);
      }
      if (node) {
        let charts = node.querySelectorAll('.tv-lightweight-charts');

        charts.forEach((item) => {
          node?.removeChild(item);
        });
      }
      chartCreated.resize(0, 0);
      setChartCreated(null);
    }
  }, [chartCreated, data, dataPrev, type, volumeWindow]);

  const formatData = (items: Array<any>, key: string) => {
    return items?.map((entry: any) => {
      return {
        // time: dayjs.unix(entry.date).utc().format('YYYY-MM-DD'),
        time: entry.date,
        value: parseFloat(entry[key]),
      };
    });
  };

  // parse the data and format for tradingview consumption
  const formattedData = formatData(data, field);
  const formattedSecondaryData = formatData(secondaryData, field);

  // adjust the scale based on the type of chart
  const topScale = type === CHART_TYPES.BAR ? 0.2 : 0.32;

  const darkMode = false;
  const textColor = darkMode ? 'white' : 'gray';

  // if no chart created yet, create one with options and add to DOM manually
  useEffect(() => {
    async function fetchPricesHistory(
      series: ISeriesApi<'Area'>,
      extraSeries: ISeriesApi<'Area'>
    ) {
      if (type === CHART_TYPES.DOUBLE_AREA) {
        const data = await store.dispatch.statisticsModel.fetchPricesChart();
        if (!data.length) {
          return;
        }
        const pricesChart = store.getState().statisticsModel.pricesChart;
        const generatedData = generatePricesChart(pricesChart);
        if (generatedData) {
          const newlyFormattedData = formatData(
            generatedData.hourlyMarketPrice,
            field
          );
          const newlyFormattedSecondaryData = formatData(
            generatedData.hourlyRedemptionPrice,
            field
          );
          series.setData(newlyFormattedData);
          extraSeries.setData(newlyFormattedSecondaryData);
          chartCreated && chartCreated.resize(0, 0);
        }
      }
    }
    if (!chartCreated && formattedData) {
      let chart = createChart(ref.current, {
        width: width,
        height: HEIGHT,
        layout: {
          backgroundColor: 'transparent',
          textColor: textColor,
        },
        rightPriceScale: {
          scaleMargins: {
            top: topScale,
            bottom: 0.3,
          },
          borderVisible: false,
        },
        timeScale: {
          borderVisible: false,
          secondsVisible: false,
          timeVisible: true,
        },
        grid: {
          horzLines: {
            color: 'rgba(197, 203, 206, 0.5)',
            visible: false,
          },
          vertLines: {
            color: 'rgba(197, 203, 206, 0.5)',
            visible: false,
          },
        },
        crosshair: {
          horzLine: {
            visible: false,
            labelVisible: false,
          },
          vertLine: {
            visible: true,
            style: 0,
            width: 2,
            color: 'rgba(32, 38, 46, 0.1)',
            labelVisible: false,
          },
        },
        localization: {
          priceFormatter: (val: any) => formattedNum(val, false),
        },
      });

      chart.applyOptions({
        layout: {
          fontFamily: 'helvetica',
        },
      });

      let series =
        type === CHART_TYPES.BAR
          ? chart.addHistogramSeries({
              color: '#61ddc7',
              priceFormat: {
                type: 'volume',
              },
              scaleMargins: {
                top: 0.32,
                bottom: 0,
              },
            })
          : chart.addAreaSeries({
              topColor: '#61ddc7',
              bottomColor: 'rgba(97, 221, 199, 0)',
              lineColor: '#61ddc7',
              lineWidth: 3,
            });

      series.setData(formattedData);

      let extraSeries =
        type === CHART_TYPES.DOUBLE_AREA
          ? chart.addAreaSeries({
              topColor: '#74d3fc',
              bottomColor: 'rgba(116, 211, 252, 0)',
              lineColor: '#74d3fc',
              lineWidth: 3,
              scaleMargins: {
                top: 0.32,
                bottom: 0,
              },
            })
          : null;

      if (extraSeries) {
        extraSeries.setData(formattedSecondaryData);
        let timer: any = null;
        chart
          .timeScale()
          .subscribeVisibleLogicalRangeChange((newVisibleLogicalRange) => {
            if (timer !== null) {
              return;
            }
            timer = setTimeout(() => {
              var logicalRange = newVisibleLogicalRange;
              if (logicalRange !== null && extraSeries) {
                var barsInfo = extraSeries.barsInLogicalRange(logicalRange);
                if (
                  barsInfo !== null &&
                  barsInfo.barsBefore < 150 &&
                  barsInfo.barsBefore > 0
                ) {
                  fetchPricesHistory(series as ISeriesApi<'Area'>, extraSeries);
                }
              }
              timer = null;
            }, 50);
          });
      }

      let toolTip = document.createElement('div');
      toolTip.setAttribute('id', 'tooltip-id' + type);
      toolTip.className = darkMode
        ? 'three-line-legend-dark'
        : 'three-line-legend';
      ref.current.appendChild(toolTip);
      toolTip.style.display = 'block';
      toolTip.style.fontWeight = '500';
      toolTip.style.left = 10 + 'px';
      toolTip.style.top = 5 + 'px';
      toolTip.style.backgroundColor = 'transparent';

      const returnDoubleAreaFormat = (
        first: number,
        second: number,
        dateStr = null
      ) => {
        return (
          `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor}">` +
          '<span style="width:10px; height:10px;margin-right:3px; vertical-align:middle; display:inline-block;background:#74d3fc;border-radius:50%;"></span> Redemption Price: $' +
          formattedNum(second, false) +
          '</div>' +
          `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor}">` +
          `<span style="width:10px; height:10px;margin-right:3px; vertical-align:middle; display:inline-block;background:#61ddc7;border-radius:50%;"></span> ${COIN_TICKER} Market Price: 
    $` +
          formattedNum(first, false) +
          '</div>' +
          `${
            dateStr
              ? `<div style="color:${textColor}">` + dateStr + '</div>'
              : ''
          }`
        );
      };

      // get the title of the chart
      const setLastBarText = () => {
        toolTip.innerHTML =
          type === CHART_TYPES.DOUBLE_AREA
            ? returnDoubleAreaFormat(base, secondaryBase)
            : `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor};">${title} ${
                type !== CHART_TYPES.AREA
                  ? type === CHART_TYPES.BAR
                    ? '(Avg)'
                    : '(1hr)'
                  : ''
              }</div>` +
              `<div style="font-size: 22px; margin: 4px 0px; color:${textColor}" >` +
              formattedNum(base ?? 0, false) +
              (type === CHART_TYPES.BAR ? '%' : '');
      };
      setLastBarText();

      // update the title when hovering on the chart
      chart.subscribeCrosshairMove(function (param: any) {
        if (
          param === undefined ||
          param.time === undefined ||
          param.point.x < 0 ||
          param.point.x > width ||
          param.point.y < 0 ||
          param.point.y > HEIGHT
        ) {
          setLastBarText();
        } else {
          let dateStr = dayjs(param.time * 1000)
            .utc()
            .format('MMMM D, YYYY h:mm A');
          let price = param.seriesPrices.get(series);
          let secondaryPrice =
            type === CHART_TYPES.DOUBLE_AREA && extraSeries
              ? param.seriesPrices.get(extraSeries)
              : null;

          const CHART_HTML =
            `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor};">${title}</div>` +
            `<div style="font-size: 22px; margin: 4px 0px; color: ${textColor}">` +
            formattedNum(price, false) +
            (type === CHART_TYPES.BAR ? '%' : '') +
            '</div>' +
            `<div style="color: ${textColor}">` +
            dateStr +
            '</div>';

          const DOUBE_AREA_HTML = returnDoubleAreaFormat(
            price,
            secondaryPrice,
            dateStr
          );
          toolTip.innerHTML =
            type === CHART_TYPES.DOUBLE_AREA ? DOUBE_AREA_HTML : CHART_HTML;
        }
      });

      if (type !== CHART_TYPES.DOUBLE_AREA) {
        chart.timeScale().fitContent();
      }

      setChartCreated(chart);
    }
  }, [
    base,
    chartCreated,
    darkMode,
    data,
    field,
    formattedData,
    formattedSecondaryData,
    secondaryBase,
    secondaryData,
    textColor,
    title,
    topScale,
    type,
    width,
    volumeWindow,
  ]);

  // responsiveness
  useEffect(() => {
    if (width) {
      chartCreated && chartCreated.resize(width, HEIGHT);
      chartCreated && chartCreated.timeScale().scrollToPosition(0, true);
    }
  }, [chartCreated, width]);

  return (
    <Wrapper>
      <div ref={ref} id={'test-id' + type} />
      <IconWrapper>
        <Play
          onClick={() => {
            chartCreated && chartCreated.timeScale().fitContent();
          }}
        />
      </IconWrapper>
    </Wrapper>
  );
};

export default Chart;

const IconWrapper = styled.div`
  position: absolute;
  right: 5px;
  border-radius: 3px;
  height: 16px;
  width: 16px;
  padding: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
