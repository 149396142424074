import axios from 'axios';
import { CONTRACT_LIST } from '../utils/constants';

const fetchFiatPrice = async (token: string = 'ethereum') => {
  // getting from coingecko
  const res = await axios.get(
    `https://api.coingecko.com/api/v3/simple/price?ids=${token}&vs_currencies=usd`
  );
  const { usd } = res.data[token];
  return usd;
};

const fetchContractList = async () => {
  const res = await axios.get(CONTRACT_LIST);
  return res.data;
};

export default {
  fetchFiatPrice,
  fetchContractList,
};
