import { BigNumber } from 'ethers';
import { utils as gebUtils } from 'geb.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useGeb from './useGeb';

const yearlyExpoentation = (val: BigNumber) =>
  ((Number(val.toString()) / 1e27) ** (86400 * 365) - 1) * 100;

export default function usePIRates() {
  const [state, setState] = useState({ pRate: 0, iRate: 0 });
  const geb = useGeb();
  const getRatesCallback = useCallback(
    ([LastPT, SG, LastIT, AG]: BigNumber[]) => {
      const pRate = yearlyExpoentation(
        gebUtils.RAY.add(LastPT.mul(SG).div(gebUtils.WAD))
      );
      const iRate = yearlyExpoentation(
        gebUtils.RAY.add(LastIT.mul(AG).div(gebUtils.WAD))
      );

      setState({ pRate, iRate });
    },
    []
  );

  useEffect(() => {
    if (!geb) return;
    console.log(geb);
    console.log(geb.contracts.piCalculator.address);
    geb
      .multiCall([
        geb.contracts.piCalculator.getLastProportionalTerm(true),
        geb.contracts.piCalculator.sg(true),
        geb.contracts.piCalculator.getLastIntegralTerm(true),
        geb.contracts.piCalculator.ag(true),
      ])
      .then(getRatesCallback)
      .catch((e) => {
        console.log(e);
      });
  }, [geb, getRatesCallback]);

  return useMemo(() => state, [state]);
}
