import React, { ReactNode } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import styled from 'styled-components';
import CookieBanner from '../components/CookieBanner';

interface Props {
  children: ReactNode;
}

const Shared = ({ children }: Props) => {
  return (
    <Container>
      <EmptyDiv>
        <Navbar isWhiteLogo />
      </EmptyDiv>
       <Content>{children}</Content>
      <EmptyDiv>
        <Footer slapToBottom />
        <CookieBanner />
      </EmptyDiv>
    </Container>
  );
};

export default Shared;

const Container = styled.div`
  min-height: 100vh;

  .CookieConsent {
    z-index: 999 !important;
    bottom: 20px !important;
    width: 90% !important;
    max-width: 1280px;
    margin: 0 auto;
    right: 0;
    border-radius: ${(props) => props.theme.global.borderRadius};
    padding: 10px 20px;

    button {
      background: ${(props) => props.theme.colors.gradient} !important;
      color: ${(props) => props.theme.colors.neutral} !important;
      padding: 8px 15px !important;
      background: ${(props) => props.theme.colors.gradient};
      border-radius: ${(props) => props.theme.global.borderRadius} !important;
      font-size: ${(props) => props.theme.font.small};
      font-weight: 600;
      cursor: pointer;
      flex: 0 0 auto;
      margin: 0px 15px 0px 0px !important;
      text-align: center;
      outline: none;
      position: relative;
      top: -5px;
    }

    @media (max-width: 991px) {
      display: block !important;
      button {
        margin-left: 10px !important;
      }
    }
  }
`;

const Content = styled.div``;
const EmptyDiv = styled.div``;
