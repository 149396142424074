import dayjs from 'dayjs';
import Numeral from 'numeral';
import { ETH_NETWORK, Network, timeframeOptions } from './constants';
import { IDailyStats } from './interfaces';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

export const formatEthAddress = (address: string, length: number = 4) =>
  `${address.slice(0, length + 2)}...${address.slice(-1 * length)}`;

export const capitalizeName = (name: string) =>
  name.charAt(0).toUpperCase() + name.slice(1);

export const amountToFiat = (balance: number, fiatPrice: number) => {
  return (balance * fiatPrice).toFixed(4);
};

export const formatNumber = (value: string, digits = 4, round = false) => {
  const nOfDigits = Array.from(Array(digits), (_) => 0).join('');
  const n = Number(value);
  if (Number.isInteger(n) || value.length < 5) {
    return n;
  }
  if (round) {
    return Number(Numeral(n).format(`0.${nOfDigits}`));
  }
  return Number(Numeral(n).format(`0.${nOfDigits}`, Math.floor));
};

export const getRatePercentage = (value: string) => {
  const rate = Number(value);
  let ratePercentage = rate < 1 ? (1 - rate) * -1 : rate - 1;
  return ratePercentage * 100;
};

export const timeout = (ms: number) =>
  new Promise((res) => setTimeout(res, ms));

export const toK = (num: string) => {
  return Numeral(num).format('0.[00]a');
};

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formattedNum = (
  number: any,
  usd = false,
  acceptNegatives = false
) => {
  if (isNaN(number) || number === '' || number === undefined) {
    return usd ? '$0' : 0;
  }
  let num = parseFloat(number);

  if (num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0));
  }

  if (num === 0) {
    if (usd) {
      return '$0';
    }
    return 0;
  }
  if (num > 0 && num < 0.001) {
    return usd ? '< $0.001' : '< 0.001';
  }

  if (num < 0 && num > -0.001) {
    return usd ? '> -$0.001' : '> -0.001';
  }

  if (num > 1000) {
    return usd
      ? '$' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
      : '' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString();
  }

  if (usd) {
    if (num < 0.1) {
      return '$' + Number(parseFloat(String(num)).toFixed(3));
    } else {
      let usdString = priceFormatter.format(num);
      return '$' + usdString.slice(1, usdString.length);
    }
  }

  return Number(parseFloat(String(num)).toFixed(3));
};

export const getTimeframe = (timeWindow: string) => {
  const utcEndTime = dayjs.utc();
  // based on window, get start time
  let utcStartTime;
  switch (timeWindow) {
    case timeframeOptions.WEEK:
      utcStartTime = utcEndTime.subtract(1, 'week').endOf('day').unix() - 1;
      break;
    case timeframeOptions.MONTH:
      utcStartTime = utcEndTime.subtract(1, 'month').endOf('day').unix() - 1;
      break;
    case timeframeOptions.ALL_TIME:
      utcStartTime = utcEndTime.subtract(1, 'year').endOf('day').unix() - 1;
      break;
    default:
      utcStartTime = utcEndTime.subtract(1, 'year').startOf('year').unix() - 1;
      break;
  }
  return utcStartTime;
};

export const getEtherscanLink = (
  data: string,
  type?: 'transaction' | 'token' | 'address' | 'block'
): string => {
  const prefix = `https://${
    ETH_NETWORK === Network.KOVAN ? 'kovan.' : ''
  }etherscan.io`;

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
};

export const uniqueBy = (
  arr: any[],
  key: string | number | any[],
  $some = false
): any[] => {
  if (key instanceof Array) {
    if ($some) {
      //@ts-ignore
      return key.reduce(uniqueBy, arr);
    } else {
      const fnUnique = (obj: { [x: string]: any }) =>
        key.reduce((a, k) => `${a}-${obj[k]}`, '');
      return Object.values(
        arr.reduce((a: { [x: string]: any }, v: any) => {
          const key = fnUnique(v);
          return a[key] === undefined ? ((a[key] = v), a) : a;
        }, {})
      );
    }
  }
  return Object.values(
    arr.reduce(
      (a: { [x: string]: any }, v: { [x: string]: string | number }) =>
        a[v[key]] === undefined ? ((a[v[key]] = v), a) : a,
      {}
    )
  );
};

export const generateRedemptionRates = (
  dailyStats: Array<IDailyStats>,
  type: 'weekly' | 'monthly' | 'quarterly' = 'weekly'
) => {
  const stats = dailyStats.reduce(
    (
      acc: { [key: string]: [{ redemptionRate: string; date: number }] },
      dailyStat
    ) => {
      // create a composed key: 'year-week'
      let key = `${dayjs.unix(dailyStat.timestamp).year()}-${dayjs
        .unix(dailyStat.timestamp)
        .week()}`;

      if (type === 'monthly') {
        key = `${dayjs.unix(dailyStat.timestamp).year()}-${dayjs
          .unix(dailyStat.timestamp)
          .month()}`;
      }

      if (type === 'quarterly') {
        key = `${dayjs.unix(dailyStat.timestamp).year()}-${dayjs
          .unix(dailyStat.timestamp)
          .quarter()}`;
      }

      const obj = {
        redemptionRate: dailyStat.redemptionRate.annualizedRate,
        date: Number(dailyStat.timestamp),
        humanized: dayjs.unix(dailyStat.timestamp).format('YYYY-MM-DD'),
        week: `${dayjs.unix(dailyStat.timestamp).year()}-${dayjs
          .unix(dailyStat.timestamp)
          .month()}-${dayjs.unix(dailyStat.timestamp).week()}`,
      };
      if (!acc[key]) {
        acc[key] = [obj];
      } else {
        acc[key].push(obj);
      }

      return acc;
    },
    {}
  );

  return Object.keys(stats).map((key: string) => {
    const averageRedRate =
      stats[key].reduce((sum, stat) => {
        return sum + Number(stat.redemptionRate);
      }, 0) / stats[key].length;
    return {
      volume: getRatePercentage(String(averageRedRate)).toString(),
      date: stats[key][0].date,
    };
  });
};
