import { action, Action } from 'easy-peasy';

const language = localStorage.getItem('lang');
const isLight = localStorage.getItem('isLight');

export interface SettingsModel {
  isLightTheme: boolean;
  lang: string;
  compactMode: boolean;
  bodyOverflow: boolean;
  setIsLightTheme: Action<SettingsModel, boolean>;
  setLang: Action<SettingsModel, string>;
  setBodyOverFlow: Action<SettingsModel, boolean>;
  setCompactMode: Action<SettingsModel, boolean>;
}

const settingsModel: SettingsModel = {
  isLightTheme: isLight ? JSON.parse(isLight) : false,
  lang: language || 'en',
  bodyOverflow: false,
  compactMode: false,
  setIsLightTheme: action((state, payload) => {
    state.isLightTheme = payload;
    localStorage.setItem('isLight', JSON.stringify(payload));
  }),
  setLang: action((state, payload) => {
    state.lang = payload;
    localStorage.setItem('lang', payload);
  }),
  setBodyOverFlow: action((state, payload) => {
    state.bodyOverflow = payload;
  }),
  setCompactMode: action((state, payload) => {
    state.compactMode = payload;
  }),
};

export default settingsModel;
